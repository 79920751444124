import {
  withStyles,
  List as MuiList,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { AppTheme, pxToRem } from "@gada-saas/web-ui";

const drawerWidth = 280;

export const List = withStyles(({ palette }: AppTheme) => ({
  root: {
    backgroundColor: palette.interface.neutral.dark,
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiList);

export const useStyles = makeStyles<AppTheme>((theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: theme.palette.interface.neutral.dark,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.interface.neutral.dark,
    },
    logoContainer: {
      padding: `${pxToRem(16)} ${pxToRem(24)}`,
      justifyContent: "space-between",
    },
    textContainer: {
      backgroundColor: theme.palette.interface.red.primary,
      padding: `${pxToRem(4)} ${pxToRem(6)}`,
      borderRadius: pxToRem(9),
      position: "absolute",
      top: 0,
      left: "50%",
      alignItems: "center",
    },
    storeSelector: {
      display: "flex",
      alignItems: "center",
      position: "sticky",
      top: 0,
      zIndex: 10,
      backgroundColor: theme.palette.interface.neutral.dark,
    },
    divider: {
      backgroundColor: theme.palette.interface.neutral.primary,
      opacity: "0.3",
    },
    stickyMenu: {
      cursor: "pointer",
      position: "sticky",
      zIndex: 10,
      bottom: "0",
      backgroundColor: theme.palette.interface.neutral.dark,
      margin: "auto 0 0 0",
      "&:hover": {
        backgroundColor: theme.palette.overlay.neutral,
      },
    },
    link: {
      textDecoration: "none",
      fontWeight: "bold",
      color: theme.palette.interface.teal.primary,
    },
    topShadow: {
      boxShadow: "0px -8px 8px rgba(0, 0, 0, 0.4)",
    },
    switcherDivider: {
      width: pxToRem(1),
      height: pxToRem(24),
      backgroundColor: theme.palette.interface.neutral.primary,
      margin: `0 ${pxToRem(24)}`,
    },
    expandIcon: {
      marginLeft: pxToRem(3),
      color: theme.palette.interface.neutral.secondary,
    },
  })
);

export default useStyles;
