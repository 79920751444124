import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles<AppTheme, { isRead: boolean }>((theme) => ({
  container: ({ isRead }) => ({
    margin: pxToRem(16),
    position: "relative",
    borderRadius: pxToRem(8),
    borderBottom: !isRead
      ? `${pxToRem(4)} solid ${theme.palette.interface.teal.dark}`
      : undefined,
    "&:hover": {
      cursor: "pointer",
    },
  }),
  captionedImage: {
    height: pxToRem(164),
    objectFit: "cover",
  },
  contentContainer: {
    padding: pxToRem(16),
    alignItems: "flex-start",
  },
  iconContainer: {
    marginRight: pxToRem(12),
  },
  icon: {
    borderRadius: "50%",
    width: pxToRem(48),
    height: pxToRem(48),
    objectFit: "cover",
  },
  titleContainer: {
    marginBottom: pxToRem(4),
    alignItems: "flex-start",
  },
  title: {
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  timestamp: {
    alignSelf: "flex-start",
    marginTop: pxToRem(4),
  },
  description: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
  },
  linkTextButton: {
    display: "flex",
    width: "fit-content",
    padding: 0,
    justifyContent: "flex-start",
  },
  pinnedContainer: {
    backgroundColor: theme.palette.interface.orange.primary,
    width: pxToRem(24),
    height: pxToRem(20),
    alignItems: "center",
    justifyContent: "center",
    borderBottomLeftRadius: pxToRem(4),
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default useStyles;
