import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  container: {
    backgroundColor: theme.palette.interface.neutral.dark,
    padding: pxToRem(16),
    borderBottomLeftRadius: pxToRem(8),
    borderBottomRightRadius: pxToRem(8),
  },
  titleContainer: {
    marginBottom: pxToRem(8),
  },
  iconButton: {
    padding: 0,
  },
  closeIcon: {
    color: theme.palette.interface.white,
    width: pxToRem(16),
    height: pxToRem(16),
  },
  tutorialTwoRow: {
    marginTop: pxToRem(24),
  },
  infoIcon: {
    width: pxToRem(20),
    height: pxToRem(20),
    color: theme.palette.interface.white,
    marginRight: pxToRem(4),
    marginTop: pxToRem(4),
    alignSelf: "flex-start",
  },
  buttonsContainer: {
    marginTop: pxToRem(24),
  },
  flex: {
    flex: 1,
  },
  height: {
    height: pxToRem(38),
  },
  // the following variables are generated by doArrow function inside useCoachMark hook and passed as popover styles
  arrow: {
    width: 0,
    height: 0,
    position: "absolute",
    top: "var(--rtp-arrow-top, auto)",
    right: "var(--rtp-arrow-right, auto)",
    bottom: "var(--rtp-arrow-bottom, auto)",
    left: "var(--rtp-arrow-left, auto)",
    borderTop: "var(--rtp-arrow-border-top)",
    borderRight: "var(--rtp-arrow-border-right)",
    borderBottom: "var(--rtp-arrow-border-bottom)",
    borderLeft: "var(--rtp-arrow-border-left)",
  },
}));

export default useStyles;
