import * as React from "react";
import pThrottle from "p-throttle";
import { db } from "@gada-saas/web-core/inventory/offline";
import { request } from "@gada-saas/web-core/common/api";
import { useSnackbar } from "notistack";
import {
  useActiveStoreState,
  useLazyGetSavedCartsQuery,
} from "@gada-saas/web-core";
import {
  CartQueryType,
  CartStatus,
} from "@gada-saas/web-core/pos/cart/constants";

const throttle = pThrottle({
  limit: 2,
  interval: 1000,
});

const throttledRequest = throttle(request);

export default function useOfflineRequestQueueExecutor() {
  const { enqueueSnackbar } = useSnackbar();
  const { storeId } = useActiveStoreState();

  const [getSavedCarts] = useLazyGetSavedCartsQuery();

  const processQueue = React.useCallback(async () => {
    try {
      const requestQueue = await db.requestQueue
        .where("apiResponseStatus")
        .equals("QUEUED")
        .toArray();
      // TODO :
      // Promise pool, concurrency here .. better not to use promise.all

      for (let i = 0; i < requestQueue.length; i++) {
        const currRequest = requestQueue[i];

        const response = await throttledRequest({
          method: currRequest.method,
          data: currRequest.data,
          url: currRequest.url,
          withCredentials: true,
        });

        if (response.data) {
          await db.requestQueue
            .where("requestQueueId")
            .equals(currRequest.requestQueueId)
            .delete();
          if (currRequest.cartId) {
            await db.carts.where("id").equals(currRequest.cartId).delete();
          }
        } else {
          enqueueSnackbar(
            "Beberapa transaksi tidak berhasil di-sync. Silakan coba lagi.",
            {
              variant: "error",
            }
          );
          await db.requestQueue.update(currRequest.requestQueueId, {
            retryCount: currRequest.retryCount + 1,
            apiResponseStatus:
              currRequest.retryCount >= 5 ? "FAILED" : "QUEUED",
            responseError: response.error,
          });
        }
      }
    } catch (err) {
      enqueueSnackbar(
        "Beberapa transaksi tidak berhasil di-sync. Silakan coba lagi.",
        {
          variant: "error",
        }
      );
      console.log("error", err);
    }
  }, [enqueueSnackbar]);

  const processSavedCartsQueue = React.useCallback(async () => {
    try {
      let countRequest = 0;
      const requestQueue = await db.savedCartsRequestQueue
        .where("apiResponseStatus")
        .equals("QUEUED")
        .toArray();
      // TODO :
      // Promise pool, concurrency here .. better not to use promise.all
      for (let i = 0; i < requestQueue.length; i++) {
        countRequest++;
        const currRequest = requestQueue[i];

        const response = await throttledRequest({
          method: currRequest.method,
          data: currRequest.data,
          url: currRequest.url,
          withCredentials: true,
        });

        if (response.data) {
          await db.savedCartsRequestQueue
            .where("requestQueueId")
            .equals(currRequest.requestQueueId)
            .delete();
          if (currRequest.cartId) {
            await db.carts.where("id").equals(currRequest.cartId).delete();
          }
        } else {
          const nextRetryCount = currRequest.retryCount + 1;
          await db.savedCartsRequestQueue.update(currRequest.requestQueueId, {
            retryCount: nextRetryCount,
            apiResponseStatus: nextRetryCount >= 5 ? "FAILED" : "QUEUED",
          });
        }
      }

      if (countRequest > 0) {
        getSavedCarts({
          storeId,
          cartStatus: CartStatus.SAVED,
          page: 1,
          pageSize: 100,
          queryType: CartQueryType.STATUS,
        });
      }
    } catch (err) {
      enqueueSnackbar(
        "Beberapa transaksi tidak berhasil di-sync. Silakan coba lagi.",
        {
          variant: "error",
        }
      );
    }
  }, [enqueueSnackbar, getSavedCarts, storeId]);

  const processSingleRequest = React.useCallback(
    async (requestQueueId) => {
      try {
        const requestQueue = await db.requestQueue
          .where("requestQueueId")
          .equals(requestQueueId)
          .toArray();
        // TODO :
        // Promise pool, concurrency here .. better not to use promise.all

        for (let i = 0; i < requestQueue.length; i++) {
          const currRequest = requestQueue[i];

          const response = await throttledRequest({
            method: currRequest.method,
            data: currRequest.data,
            url: currRequest.url,
            withCredentials: true,
          });

          if (response.data) {
            await db.requestQueue
              .where("requestQueueId")
              .equals(currRequest.requestQueueId)
              .delete();
          } else {
            enqueueSnackbar("Sync gagal. Silakan coba lagi.", {
              variant: "error",
            });
            const nextRetryCount = currRequest.retryCount + 1;
            await db.requestQueue.update(currRequest.requestQueueId, {
              retryCount: nextRetryCount,
              apiResponseStatus: nextRetryCount >= 5 ? "FAILED" : "QUEUED",
            });
          }
        }
      } catch (err) {
        enqueueSnackbar("Sync gagal. Silakan coba lagi.", {
          variant: "error",
        });
        console.log("error", err);
      }
    },
    [enqueueSnackbar]
  );

  const deleteRequest = React.useCallback(async (requestQueueId) => {
    try {
      await db.requestQueue
        .where("requestQueueId")
        .equals(requestQueueId)
        .delete();
      // TODO :
      // Promise pool, concurrency here .. better not to use promise.all
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  return {
    processQueue,
    processSingleRequest,
    deleteRequest,
    processSavedCartsQueue,
  };
}
