import * as React from "react";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import mergeRefs from "react-merge-refs";
import { iVirtualizedInfiniteScrollListProps } from "./types";
import { EmptyListMessage } from "../../EmptyListMessage";
import { Col } from "../../Layout";

const VirtualizedInfiniteScrollList: React.FC<
  iVirtualizedInfiniteScrollListProps<any>
> = ({
  data,
  loading = true,
  gap = 16,
  hasNextPage = false,
  layout = "vertical",
  onLoadMore,
  renderItem,
  emptyListMessageProps = {
    title: "Barang Yang Anda Cari Tidak Ditemukan",
    message: "Gunakan kata kunci lain atau tambahkan barang secara manual",
  },
  loaderComponent,
  emptyListComponent,
}) => {
  const listRef = React.useRef<List>(null);
  const rowHeights = React.useRef<Record<number, number>>({});

  const loadMoreItems = React.useMemo(
    () =>
      typeof onLoadMore === "function"
        ? onLoadMore
        : (_: number, __: number) => new Promise((resolve) => resolve([])),
    [onLoadMore]
  );

  const itemCount = hasNextPage ? data.length + 1 : data.length;

  const isItemLoaded = (index: number): boolean =>
    !hasNextPage || index < data.length;

  const getRowHeight = (index: number): number => {
    return rowHeights.current[index] + 8 || 82;
  };

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const Row = ({
    index,
    style,
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const rowRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight + gap - 8);
      }
    }, [index, rowRef]);

    return (
      <div style={style}>
        {isItemLoaded(index) ? (
          renderItem(data[index], index, undefined, {}, rowRef)
        ) : loaderComponent ? (
          loaderComponent(style)
        ) : (
          <div ref={rowRef}>Loading...</div>
        )}
      </div>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <AutoSizer>
        {({ width, height }) =>
          loading && itemCount === 0 ? (
            loaderComponent ? (
              loaderComponent({ width, height })
            ) : (
              <div style={{ width, height }}>Loading...</div>
            )
          ) : itemCount === 0 ? (
            <Col style={{ width, height }}>
              {emptyListComponent || (
                <EmptyListMessage
                  {...emptyListMessageProps}
                  style={{ flex: 1 }}
                />
              )}
            </Col>
          ) : (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  ref={mergeRefs([ref, listRef])}
                  width={width}
                  height={height}
                  layout={layout}
                  itemCount={itemCount}
                  itemSize={getRowHeight}
                  onItemsRendered={onItemsRendered}
                >
                  {Row}
                </List>
              )}
            </InfiniteLoader>
          )
        }
      </AutoSizer>
    </div>
  );
};

export default VirtualizedInfiniteScrollList;
