import { useGlobalOfflineState } from "@gada-saas/web-core/offline";
import { Text } from "@gada-saas/web-ui";
import { Box, Collapse } from "@material-ui/core";
import clsx from "clsx";
import { useRouter } from "next/router";
import * as React from "react";
import useStyles from "./styles";
import { OfflineIndicatorProps } from "./types";

export default function OfflineIndicator({
  collapseClassName,
  message = "Menampilkan versi offline, beberapa fitur tidak bisa digunakan",
}: OfflineIndicatorProps) {
  const paddingTopMissingPages = ["refund", "return", "destroy"];

  const router = useRouter();
  const currentPage = router.pathname.split("/").pop() || "";

  const styles = useStyles({
    paddingTop: paddingTopMissingPages.includes(currentPage),
  });

  const { isOffline } = useGlobalOfflineState();
  const [showBanner, setShowBanner] = React.useState(false);

  // Consider using close icon similar to InventoryProductProposalBanner
  /**
   * Lifecycle
   */
  React.useEffect(() => {
    setShowBanner(isOffline);
  }, [isOffline]);

  return (
    <Collapse
      in={showBanner}
      className={clsx(styles.collapse, collapseClassName)}
    >
      <Box className={styles.container}>
        <Text variant="body2">{message}</Text>
      </Box>
    </Collapse>
  );
}
