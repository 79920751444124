import * as React from "react";
import {
  useGadaOfflineActions,
  useGlobalOfflineState,
} from "@gada-saas/web-core/offline";
import { Detector } from "react-detect-offline";
import { useSnackbar } from "notistack";

export default function OfflineDetector() {
  const GadaOfflineActions = useGadaOfflineActions();
  const GaadaOfflineState = useGlobalOfflineState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChangeNetwork = (isOnline: boolean) => {
    if (isOnline) {
      closeSnackbar(GaadaOfflineState.snackbarKey);
      GadaOfflineActions.markOnline();
    } else {
      const key = enqueueSnackbar(
        "Anda dalam keadaan offline, beberapa fungsi mungkin tidak dapat bekerja semestinya",
        {
          variant: "warning",
        }
      );
      GadaOfflineActions.markOffline(key);
    }
  };

  return (
    <Detector
      polling={true}
      onChange={handleChangeNetwork}
      render={() => null}
    />
  );
}
