import { pxToRem } from "@gada-saas/web-ui";
import { AppTheme } from "@gada-saas/web-ui/theme";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette }: AppTheme) => ({
  infoIconTheme: {
    color: palette.interface.neutral.secondary,
    fontSize: pxToRem(20),
  },
}));
