import {
  Box,
  Popover,
  PopoverProps,
  Divider,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { Text, AppTheme, pxToRem } from "@gada-saas/web-ui";
import { PAGES } from "@common/constants";
import { useLogoutUser } from "./useLogoutUser";
import { useRouter } from "next/router";

type UserSettingsPopoverProps = Omit<PopoverProps, "onClose"> & {
  onClose: () => void;
};

const UserSettingsPopover = (props: UserSettingsPopoverProps) => {
  const theme = useTheme<AppTheme>();
  const router = useRouter();
  const logoutUser = useLogoutUser();

  const handleClickLogout = () => {
    logoutUser();
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          minWidth: pxToRem(220),
        },
      }}
    >
      <Box
        padding={4}
        style={{ cursor: "pointer" }}
        onClick={() => router.push(PAGES.USER_ACCOUNT)}
      >
        <Text variant="body1">Akun & Pengaturan</Text>
      </Box>
      <Divider
        style={{ backgroundColor: theme.palette.interface.neutral.light }}
      />
      {/* TODO: Redirect to setting */}
      {/* <Box padding={4} style={{ cursor: "pointer" }}>
        <Text variant="body1">Pengaturan</Text>
      </Box> */}
      <Divider
        style={{ backgroundColor: theme.palette.interface.neutral.light }}
      />
      {/* TODO: Logout implementation */}
      <Box
        padding={4}
        style={{ cursor: "pointer" }}
        onClick={handleClickLogout}
      >
        <Text variant="body1" ink="redPrimary">
          Keluar
        </Text>
      </Box>
    </Popover>
  );
};

export default UserSettingsPopover;
