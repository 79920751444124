import { AppTheme } from "@gada-saas/web-ui";
import { makeStyles, createStyles } from "@material-ui/core";

const drawerWidth = 280;

export const useStyles = makeStyles<
  AppTheme,
  {
    mainBgColor: "white" | "neutralLight" | "neutralLighter";
    fullWidthRoot: boolean;
    hideSideNavBar: boolean;
  }
>((theme) =>
  createStyles({
    root: ({ fullWidthRoot }) => ({
      display: "flex",
      flex: fullWidthRoot ? 1 : "auto",
    }),
    appBar: {
      width: ({ hideSideNavBar }) =>
        `calc(100% - ${!hideSideNavBar ? drawerWidth : 0}px)`,
      marginLeft: drawerWidth,
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    },
    toolbar: {
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      backgroundColor: (props) =>
        props.mainBgColor === "white"
          ? theme.palette.interface.white
          : props.mainBgColor === "neutralLight"
          ? theme.palette.interface.neutral.light
          : theme.palette.interface.neutral.lighter,
      minHeight: "100vh",
      width: ({ hideSideNavBar }) =>
        `calc(100% - ${!hideSideNavBar ? drawerWidth : 0}px)`,
    },
    mainContent: {
      padding: theme.spacing(3),
      minHeight: "calc(100vh - 64px)",
    },
    rightSection: {
      zIndex: 1200, // AppBar is 1100
    },
  })
);
