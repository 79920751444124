import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";

const AddInventoryChooseStockUnitTutorialStep: React.VFC<PopoverContentProps> = (
  props
) => {
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Pilih Unit Stok"
      description={
        <Text variant="body2" ink="neutralLight">
          Pilih tipe unit barang yang Anda miliki sebagai stok di toko.
        </Text>
      }
      primaryButtonText="Mengerti"
      customPrimaryButtonClick={() => {
        analytics.track("Inventory Coachmark Clicked", {
          coachmark_name: "Store UOM",
          action_text: "Mengerti",
          has_inventory: false,
        });
      }}
      customDismissButtonClick={() => {
        analytics.track("Inventory Coachmark Dismissed", {
          coachmark_name: "Store UOM",
          action_text: "Mengerti",
          has_inventory: false,
        });
      }}
      onComponentRendered={() => {
        analytics.track("Inventory Coachmark Shown", {
          coachmark_name: "Store UOM",
          action_text: "Mengerti",
          has_inventory: false,
        });
      }}
      {...props}
    />
  );
};

export default AddInventoryChooseStockUnitTutorialStep;
