import { Card } from "@braze/web-sdk";
import { useNotifications } from "@common/context/NotificationsProvider";
import { useDebouncedValue } from "@common/hooks";
import {
  Col,
  IconButton,
  pxToRem,
  Row,
  Text,
  theme,
  VirtualizedInfiniteScrollList,
} from "@gada-saas/web-ui";
import { Drawer } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import * as React from "react";
import { ContentCard } from "../ContentCard";
import useStyles from "./styles";
import { NotificationNavProps } from "./types";

const NotificationNav: React.VFC<NotificationNavProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  const [unreadContentCards, setUnreadContentCards] = React.useState<
    Record<string, Card>
  >({});

  const debouncedUnreadContentCards = useDebouncedValue(
    unreadContentCards,
    1500
  );

  const brazeSdk = useNotifications();

  const notifications = React.useMemo(() => {
    if (brazeSdk?.isBrazeInit) {
      return Object.values(brazeSdk.contentCards);
    }
    return [];
  }, [brazeSdk?.contentCards, brazeSdk?.isBrazeInit]);

  const updateToBraze = React.useCallback(async () => {
    const unreadNotifications = Object.values(debouncedUnreadContentCards);
    if (unreadNotifications.length === 0) {
      return;
    }

    if (brazeSdk?.isBrazeInit) {
      const { logCardImpressions } = await import(
        /* webpackExports: ["logCardImpressions"] */
        "@braze/web-sdk"
      );
      const isLogged = logCardImpressions(unreadNotifications, true);
      if (isLogged) {
        setUnreadContentCards({});
      }
    }
  }, [brazeSdk?.isBrazeInit, debouncedUnreadContentCards]);

  React.useEffect(() => {
    updateToBraze();
  }, [updateToBraze]);

  const updateUnreadContentCards = React.useCallback(
    (card: Card) => {
      if (brazeSdk?.isBrazeInit) {
        const _contentCards = brazeSdk.contentCards;
        if (!_contentCards[card.id!].isRead) {
          _contentCards[card.id!].isRead = true;
          brazeSdk.setContentCards(_contentCards);
          brazeSdk.setUnreadCards((unreadCards) => unreadCards - 1);
          setUnreadContentCards((cards) => ({ ...cards, [card.id!]: card }));
        }
      }
    },
    [brazeSdk]
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      style={{ zIndex: 1100 }}
      SlideProps={{
        style: {
          marginLeft: 280,
          width: pxToRem(388),
          backgroundColor: theme.palette.interface.neutral.light,
        },
      }}
    >
      <Row
        bgcolor={theme.palette.interface.white}
        justifyContent="space-between"
        padding={pxToRem(24)}
        paddingBottom={pxToRem(8)}
      >
        <Text variant="h2">Notifikasi</Text>
        <IconButton className={classes.iconButton} onClick={onClose}>
          <Close className={classes.close} />
        </IconButton>
      </Row>

      <Col flex={1}>
        {brazeSdk?.isBrazeInit && (
          <VirtualizedInfiniteScrollList
            data={notifications}
            renderItem={(card, index, _listName, _style, ref) => {
              if (index === notifications.length - 1) {
                return (
                  <Col ref={ref}>
                    <ContentCard
                      ref={ref}
                      key={card.id}
                      updateUnreadContentCards={updateUnreadContentCards}
                      {...card}
                    />

                    <Text
                      variant="body2"
                      ink="neutralPrimary"
                      align="center"
                      className={classes.endingText}
                    >
                      Anda sudah melihat semua notifikasi 2 minggu terakhir
                    </Text>
                  </Col>
                );
              }
              return (
                <ContentCard
                  ref={ref}
                  key={card.id}
                  updateUnreadContentCards={updateUnreadContentCards}
                  {...card}
                />
              );
            }}
            loading={false}
            emptyListMessageProps={{
              title: "Belum Ada Notifikasi Baru 2 Minggu Ini",
              titleProps: { variant: "h4" },
              message: (
                <Text
                  variant="body2"
                  align="center"
                  ink="neutralPrimary"
                  className={classes.emptyListMessageText}
                >
                  Kami akan memberi tahu kabar terbaru dari supplier dan
                  GudangAda Solusi di sini
                </Text>
              ),
            }}
          />
        )}
      </Col>
    </Drawer>
  );
};

export default NotificationNav;
