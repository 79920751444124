import * as React from "react";
import { useQueryParams } from "@common/hooks";
import { Box, Collapse } from "@material-ui/core";
import { useInventoryProposalBannerStyles } from "./styles";
import { Text } from "@gada-saas/web-ui";
import { Close } from "@material-ui/icons";

export default function InventoryProductProposalBanner() {
  const [showBanner, setShowBanner] = React.useState(false);
  const { proposedProductUnitName, proposalStatus } = useQueryParams();

  const styles = useInventoryProposalBannerStyles({
    status: proposalStatus,
  });

  /**
   * Lifecycle
   */
  React.useEffect(() => {
    if (proposalStatus && proposalStatus !== "PENDING") {
      setShowBanner(true);
    }
  }, [proposalStatus]);

  /**
   * Handler
   */
  const handleClickClose = () => setShowBanner(false);

  return (
    <Collapse in={showBanner}>
      <Box className={styles.container}>
        <Text ink={proposalStatus === "APPROVED" ? "neutralDark" : "redDark"}>
          Pengajuan unit {proposedProductUnitName} untuk dijual online{" "}
          {proposalStatus === "APPROVED" ? "diterima" : "ditolak"}
        </Text>
        <Close onClick={handleClickClose} className={styles.icon} />
      </Box>
    </Collapse>
  );
}
