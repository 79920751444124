import { TourStepContent } from "@coach-mark/components/TourStepContent";
import {
  coachMarkChains,
  setTutorialSteps,
  TutorialCode,
} from "@gada-saas/web-core";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps, useTour } from "@reactour/tour";
import * as React from "react";
import { useDispatch } from "react-redux";
import { inventoryListPageTutorialSteps } from "../../Tutorials";

const InventoryListSeeCurrentStockTutorialStep: React.VFC<PopoverContentProps> =
  (props) => {
    const dispatch = useDispatch();
    const analytics = useSegmentAnalytics();
    const { setSteps, setCurrentStep, setIsOpen } = useTour();

    return (
      <TourStepContent
        hideStepCount={true}
        title="Jumlah Stok Terkini"
        description={
          <Text variant="body2" ink="neutralLight">
            Transaksi pembelian pada kasir akan mengurangi jumlah stok barang.
          </Text>
        }
        primaryButtonText="Lanjut"
        customPrimaryButtonClick={() => {
          dispatch(
            setTutorialSteps({
              chainName: coachMarkChains.HAS_INVENTORY_LIST,
              tutorialName: TutorialCode.INVENTORY_LIST_EDIT_STOCK_AMOUNT,
              totalTutorialSteps: 1,
            })
          );
          setSteps([inventoryListPageTutorialSteps[1]]);
          setCurrentStep(0);
          setIsOpen(true);

          analytics.track("Inventory Coachmark Clicked", {
            coachmark_name: "Current Total Stock",
            action_text: "Lanjut",
            has_inventory: true,
          });
        }}
        customDismissButtonClick={() => {
          dispatch(
            setTutorialSteps({
              chainName: coachMarkChains.HAS_INVENTORY_LIST,
              tutorialName: TutorialCode.INVENTORY_LIST_EDIT_STOCK_AMOUNT,
              totalTutorialSteps: 1,
            })
          );
          setSteps([inventoryListPageTutorialSteps[1]]);
          setCurrentStep(0);
          setIsOpen(true);

          analytics.track("Inventory Coachmark Dismissed", {
            coachmark_name: "Current Total Stock",
            action_text: "Lanjut",
            has_inventory: true,
          });
        }}
        onComponentRendered={() => {
          analytics.track("Inventory Coachmark Shown", {
            coachmark_name: "Current Total Stock",
            action_text: "Mengerti",
            has_inventory: false,
          });
        }}
        {...props}
      />
    );
  };

export default InventoryListSeeCurrentStockTutorialStep;
