import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";

const HasTransactionTutorialStep3: React.VFC<PopoverContentProps> = (props) => {
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Menu Bantuan & Tutorial"
      description={
        <Text variant="body2" ink="neutralLight">
          Jika Anda memiliki pertanyaan/kesulitan terhadap penggunaan aplikasi,
          silahkan akses menu ini ya.
        </Text>
      }
      primaryButtonText="Selesai"
      secondaryButtonText="Kembali"
      customPrimaryButtonClick={() => {
        analytics.track("Homepage Coachmark Clicked", {
          coachmark_name: "homepage help center",
          action_text: "Selesai",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 2,
        });
      }}
      customSecondaryButtonClick={() => {
        analytics.track("Homepage Coachmark Clicked", {
          coachmark_name: "homepage help center",
          action_text: "Kembali",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 2,
        });

        analytics.track("Homepage Coachmark Shown", {
          coachmark_name: "homepage sell",
          action_text: "Lanjut",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 1,
        });
      }}
      customDismissButtonClick={() => {
        analytics.track("Homepage Coachmark Dismissed", {
          coachmark_name: "homepage help center",
          action_text: "Selesai",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 2,
        });
      }}
      {...props}
    />
  );
};

export default HasTransactionTutorialStep3;
