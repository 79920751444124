import { Page } from "@common/constants";
import { useUserStorePermission } from "@common/hooks";
import {
  useSideMenuState,
  useSideMenuActions,
  useUserMenusActions,
} from "@gada-saas/web-core";
import { AppTheme, pxToRem, Text } from "@gada-saas/web-ui";
import {
  createStyles,
  Divider,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { useRouter } from "next/router";
import * as React from "react";

type ItemMenuProps = Page & {
  parentMenuId?: string;
  nested?: boolean;
};

const ItemMenu = ({
  id,
  permissions,
  targetUrl,
  text,
  icon: Icon,
  nested,
  withDivider,
  parentMenuId,
}: ItemMenuProps) => {
  const analytics = useSegmentAnalytics();
  const router = useRouter();
  const styles = useStyles();
  const { activeMenuId, activeSubMenuId } = useSideMenuState();
  const [storePermissions, userHasPermission] = useUserStorePermission();
  const SideMenuActions = useSideMenuActions();
  const UserMenusActions = useUserMenusActions();

  /**
   * ----
   * Formatting
   * ----
   */
  const disableItemMenu = React.useMemo(
    () => !userHasPermission(permissions),
    [permissions, userHasPermission]
  );

  const itemMenuSelected = React.useMemo(
    () =>
      nested && parentMenuId ? activeSubMenuId === id : activeMenuId === id,
    [activeMenuId, activeSubMenuId, id, nested, parentMenuId]
  );

  /**
   * ----
   * Handlers
   * ----
   */
  const handleClick = React.useCallback(() => {
    if (!!storePermissions && !disableItemMenu) {
      analytics.track("Menu List Clicked", {
        name: text,
        section: parentMenuId ? "Sub Menu List" : "Main Menu List",
        is_enabled: true,
      });
      router.push(targetUrl).then(() =>
        SideMenuActions.setState({
          activeMenuId: nested && parentMenuId ? parentMenuId : id,
          activeSubMenuId: nested && parentMenuId ? id : "",
        })
      );
    } else {
      analytics.track("Menu List Clicked", {
        name: text,
        section: parentMenuId ? "Sub Menu List" : "Main Menu List",
        is_enabled: false,
      });
      UserMenusActions.setShowNoAccessPopup(true);
    }
  }, [
    SideMenuActions,
    UserMenusActions,
    analytics,
    disableItemMenu,
    id,
    nested,
    parentMenuId,
    router,
    storePermissions,
    targetUrl,
    text,
  ]);

  if (nested) {
    return (
      <React.Fragment>
        <NestedListItem
          button
          onClick={handleClick}
          disabled={storePermissions && disableItemMenu}
          selected={itemMenuSelected}
        >
          {typeof Icon !== "undefined" && (
            <ListItemIcon className={styles.icon}>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Text variant="h4" ink={itemMenuSelected ? "tealLight" : "white"}>
                {text}
              </Text>
            }
          />
          {storePermissions && disableItemMenu && <LockOutlinedIcon />}
        </NestedListItem>
        {withDivider && <Divider className={styles.divider} />}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={handleClick}
        disabled={storePermissions && disableItemMenu}
        selected={itemMenuSelected}
      >
        {typeof Icon !== "undefined" && (
          <ListItemIcon className={styles.icon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Text variant="h4" ink={itemMenuSelected ? "tealLight" : "white"}>
              {text}
            </Text>
          }
        />
        {storePermissions && disableItemMenu && <LockOutlinedIcon />}
      </ListItem>
      {withDivider && <Divider className={styles.divider} />}
    </React.Fragment>
  );
};

const useStyles = makeStyles<AppTheme>((theme) =>
  createStyles({
    icon: { color: theme.palette.interface.white },
    divider: {
      backgroundColor: theme.palette.interface.neutral.primary,
      opacity: "0.3",
    },
  })
);

export const ListItem = withStyles((theme: AppTheme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.interface.neutral.dark,
    color: theme.palette.interface.white,
    "&:hover": {
      backgroundColor: theme.palette.overlay.neutral,
    },

    // Selected Props
    "&$selected": {
      backgroundColor: theme.palette.overlay.teal,
      "&::before": {
        position: "absolute",
        backgroundColor: theme.palette.interface.teal.light,
        left: 0,
        content: "''",
        width: pxToRem(4),
        height: pxToRem(52),
        zIndex: 5,
      },
    },
    "&$selected:hover": {
      backgroundColor: theme.palette.overlay.teal,
    },
    "&$disabled": {
      pointerEvents: "auto",
    },
  },
  selected: {},
  disabled: {},
}))(MuiListItem);

export const NestedListItem = withStyles((theme: AppTheme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.interface.neutral.dark,
    color: theme.palette.interface.white,
    paddingLeft: theme.spacing(20),
    "&:hover": {
      backgroundColor: theme.palette.overlay.neutral,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      width: pxToRem(1),
      left: "36px",
      height: "100%",
      backgroundColor: theme.palette.interface.neutral.primary,
      zIndex: 5,
      opacity: "0.3",
    },

    // Selected Props
    "&$selected": {
      position: "relative",
      backgroundColor: theme.palette.interface.neutral.dark,
      "&::before": {
        position: "absolute",
        backgroundColor: theme.palette.interface.teal.light,
        left: "36px",
        content: "''",
        width: pxToRem(4),
        height: pxToRem(52),
        zIndex: 6,
      },
      "&:hover": {
        backgroundColor: theme.palette.overlay.neutral,
      },
    },
    "&$disabled": {
      pointerEvents: "auto",
    },
  },
  selected: {},
  disabled: {},
}))(MuiListItem);

export default ItemMenu;
