import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";
import { hasTransactionTutorialSteps } from "../../Tutorials";

const HasTransactionTutorialStep1: React.VFC<PopoverContentProps> = (props) => {
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Laporan Transaksi Terupdate"
      description={
        <Text variant="body2" ink="neutralLight">
          Setiap adanya transaksi penjualan dan pembelian, Anda dapat melihat
          detail laporannya melalui fitur ini.
        </Text>
      }
      primaryButtonText="Lanjut"
      customPrimaryButtonClick={() => {
        // auto scroll navbar to bottom to avoid slow scroll down causing coach mark modal to glitch
        const el = document.getElementsByClassName(
          hasTransactionTutorialSteps[1].selector.toString().substring(1)
        )[0];
        el?.scrollIntoView(true);

        analytics.track("Homepage Coachmark Clicked", {
          coachmark_name: "homepage report",
          action_text: "Lanjut",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 0,
        });

        analytics.track("Homepage Coachmark Shown", {
          coachmark_name: "homepage sell",
          action_text: "Lanjut",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 1,
        });
      }}
      customDismissButtonClick={() => {
        analytics.track("Homepage Coachmark Dismissed", {
          coachmark_name: "homepage report",
          action_text: "Lanjut",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 0,
        });
      }}
      onComponentRendered={() => {
        analytics.track("Homepage Coachmark Shown", {
          coachmark_name: "homepage report",
          action_text: "Lanjut",
          has_inventory: true,
          has_transactions: true,
          step_Sequence: 0,
        });
      }}
      {...props}
    />
  );
};

export default HasTransactionTutorialStep1;
