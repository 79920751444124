import { makeStyles } from "@material-ui/core";
import { AppTheme } from "../../..";
import { pxToRem } from "../../../utils";
import { iColProps } from "./types";

export const useStyles = makeStyles<AppTheme, iColProps>({
  container: (props) => ({
    display: "flex",
    height: props.fullHeight ? "100%" : "auto",
    flex: props.flex,
    flexDirection: "column",
    justifyContent: props.justifyContent
      ? props.justifyContent
      : props.vCenter
      ? "center"
      : "none",
    alignItems: props.alignItems
      ? props.alignItems
      : props.hCenter
      ? "center"
      : props.stretchContent
      ? "stretch"
      : "none",

    "&>*:not(:last-child)": {
      marginBottom: props.spacing && pxToRem(props.spacing),
    },

    "&>*:last-child": {
      marginBottom: props.showBottomSpacing ? pxToRem(props.spacing) : 0,
    },
  }),
});
