import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Divider,
  Popover,
  Radio,
  Box,
  useTheme,
} from "@material-ui/core";
import { Text, AppTheme } from "@gada-saas/web-ui";
import AddIcon from "@material-ui/icons/Add";
import { useRouter } from "next/router";
import { PAGES } from "@common/constants";
import {
  useGetUserInfoQuery,
  useActiveStoreState,
  useUserMenusActions,
  useCartActions,
} from "@gada-saas/web-core";
import { StoreSwitcherPopoverProps } from "./types";
import { ActiveStoreChangeModal } from "@miscellaneous/navigation/components/ActiveStoreChangeModal";
import { UserMenusStateType } from "@gada-saas/web-core/user/menus/types";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";

const StoreSwitcherPopover = ({
  storeChangedProps,
  ...props
}: StoreSwitcherPopoverProps) => {
  const analytics = useSegmentAnalytics();
  const theme = useTheme<AppTheme>();
  const router = useRouter();
  const showStoreChangeConfirmation = Boolean(storeChangedProps);
  const [newStore, setNewStore] = React.useState<
    UserMenusStateType["activeStore"] | null
  >(null);

  const { data: userInfoData } = useGetUserInfoQuery();
  const { storeId } = useActiveStoreState();
  const UserMenusActions = useUserMenusActions();
  const CartActions = useCartActions();

  /**
   * ----
   * Handlers
   * ----
   */
  const goToAddNewStore = React.useCallback(() => {
    analytics.track("Add Store Button Clicked");
    router.push(PAGES.ADD_NEW_STORE);
  }, [analytics, router]);

  const handleChangeStore = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (userInfoData) {
        const store = userInfoData.data.userStoreList.find(
          (store) => store.storeId === event.target.value
        );
        if (store) {
          if (showStoreChangeConfirmation) {
            setNewStore({
              storeId: store.storeId,
              storeName: store.storeName,
              isStoreLinked: store.isStoreLinked,
              storePermissionList: store.storePermissionList,
              storeRoleList: store.storeRoleList,
              hasSpecialtyType: store.hasSpecialtyType,
            });
          } else {
            UserMenusActions.setActiveStore({
              storeId: store.storeId,
              storeName: store.storeName,
              isStoreLinked: store.isStoreLinked,
              storePermissionList: store.storePermissionList,
              storeRoleList: store.storeRoleList,
              hasSpecialtyType: store.hasSpecialtyType,
            });
            CartActions.clearCart();
          }
          props.onClose();
        }
      }
    },
    [
      userInfoData,
      showStoreChangeConfirmation,
      props,
      UserMenusActions,
      CartActions,
    ]
  );

  const onConfirmStoreChange = React.useCallback(() => {
    if (!newStore) return;
    UserMenusActions.setActiveStore(newStore);
    setNewStore(null);
  }, [newStore, UserMenusActions]);

  const onCancelStoreChange = React.useCallback(() => {
    setNewStore(null);
  }, []);

  const onClickPrimaryButton = React.useCallback(
    (e) => {
      storeChangedProps?.onClickPrimaryButton?.(e);
      onConfirmStoreChange();
    },
    [storeChangedProps, onConfirmStoreChange]
  );

  const onClickSecondaryButton = React.useCallback(
    (e) => {
      storeChangedProps?.onClickSecondaryButton?.(e);
      if (!storeChangedProps?.stopCancelStoreChangeOnSecondaryButtonClick) {
        onCancelStoreChange();
      }
      if (storeChangedProps?.onConfirmStoreChangeOnSecondaryButton) {
        onConfirmStoreChange();
      }
    },
    [storeChangedProps, onCancelStoreChange, onConfirmStoreChange]
  );

  const onClickCloseButton = React.useCallback(
    (e) => {
      onCancelStoreChange();
      storeChangedProps?.onClickCloseButton?.(e);
    },
    [storeChangedProps, onCancelStoreChange]
  );

  return (
    <>
      <Popover
        open={props.open}
        onClose={props.onClose}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FormControl
          style={{
            width: "360px",
          }}
        >
          <RadioGroup value={storeId} onChange={handleChangeStore}>
            {userInfoData?.data.userStoreList.map((store) => (
              <React.Fragment key={store.storeId}>
                <FormControlLabel
                  style={{
                    padding: "16px 24px 7px 15px",
                  }}
                  control={<Radio style={{ paddingRight: "18px" }} />}
                  value={store.storeId}
                  label={
                    <React.Fragment>
                      <Text variant="h4" style={{ marginBottom: "8px" }}>
                        {store.storeName}
                      </Text>
                      <Text variant="body2" ink="neutralPrimary">
                        {store.storeAddress}
                      </Text>
                    </React.Fragment>
                  }
                />
                <Divider
                  style={{
                    backgroundColor: theme.palette.interface.neutral.light,
                  }}
                />
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>
        <Box
          display="flex"
          alignItems="center"
          paddingY={5}
          paddingX={7}
          style={{ cursor: "pointer" }}
          onClick={goToAddNewStore}
        >
          <AddIcon
            style={{
              color: theme.palette.interface.teal.primary,
              marginRight: "21px",
            }}
          />
          <Text variant="button" ink="tealPrimary">
            Tambah Toko
          </Text>
        </Box>
      </Popover>
      {storeChangedProps && (
        <ActiveStoreChangeModal
          {...storeChangedProps}
          open={Boolean(newStore)}
          // if close button, then cancel logic added to it.
          {...(storeChangedProps.withCloseButton && {
            onClickCloseButton: onClickCloseButton,
          })}
          onClickSecondaryButton={onClickSecondaryButton}
          onClickPrimaryButton={onClickPrimaryButton}
        />
      )}
    </>
  );
};

export default StoreSwitcherPopover;
