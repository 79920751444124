import { TourStepContent } from "@coach-mark/components/TourStepContent";
import {
  coachMarkChains,
  setTutorialSteps,
  TutorialCode,
} from "@gada-saas/web-core";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps, useTour } from "@reactour/tour";
import * as React from "react";
import { useDispatch } from "react-redux";
import { posIntroTutorialSteps } from "../../Tutorials";

const InventoryListEditStockAmountTutorialStep: React.VFC<PopoverContentProps> =
  (props) => {
    const dispatch = useDispatch();
    const analytics = useSegmentAnalytics();
    const { setSteps, setCurrentStep, setIsOpen } = useTour();

    return (
      <TourStepContent
        title="Mengubah Stok Barang"
        hideStepCount={true}
        description={
          <Text variant="body2" ink="neutralLight">
            Tekan tombol edit untuk mengubah stok barang Anda melalui pencatatan
            pembelian/ubah stok langsung
          </Text>
        }
        primaryButtonText="Mengerti"
        customPrimaryButtonClick={() => {
          dispatch(
            setTutorialSteps({
              chainName: coachMarkChains.HAS_INVENTORY_POS,
              tutorialName: TutorialCode.POS_INTRO,
              totalTutorialSteps: 1,
            })
          );
          setSteps(posIntroTutorialSteps);
          setCurrentStep(0);
          setIsOpen(true);

          analytics.track("Inventory Coachmark Clicked", {
            coachmark_name: "Edit Stock",
            action_text: "Mengerti",
            has_inventory: true,
          });
        }}
        customDismissButtonClick={() => {
          dispatch(
            setTutorialSteps({
              chainName: coachMarkChains.HAS_INVENTORY_POS,
              tutorialName: TutorialCode.POS_INTRO,
              totalTutorialSteps: 1,
            })
          );
          setSteps(posIntroTutorialSteps);
          setCurrentStep(0);
          setIsOpen(true);

          analytics.track("Inventory Coachmark Dismissed", {
            coachmark_name: "Edit Stock",
            action_text: "Mengerti",
            has_inventory: true,
          });
        }}
        onComponentRendered={() => {
          analytics.track("Inventory Coachmark Shown", {
            coachmark_name: "Edit Stock",
            action_text: "Mengerti",
            has_inventory: true,
          });
        }}
        {...props}
      />
    );
  };

export default InventoryListEditStockAmountTutorialStep;
