import * as React from "react";
import { initialState as userMenuInitialState } from "@gada-saas/web-core/user/menus/slice";
import { useRouter } from "next/router";
import {
  clearIsLoggedIn,
  clearUserToken,
} from "@gada-saas/web-core/common/utils";
import { useLogoutMutation } from "@gada-saas/web-core/auth/otp";
import { PAGES } from "@common/constants";
import { useDispatch } from "react-redux";

import { globalResetAction } from "@gada-saas/web-core/common/redux/actions";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { useTour } from "@reactour/tour";
import { useNotifications } from "@common/context/NotificationsProvider";
import { useRouteHistory } from "@common/context/RouteHistoryProvider/RouteHistoryProvider";
import { gadaSaasApi } from "@gada-saas/web-core/base-rtkq-api";

export function useLogoutUser() {
  const router = useRouter();

  const [logoutFromServer] = useLogoutMutation();
  const dispatch = useDispatch();
  const analytics = useSegmentAnalytics();
  const { isOpen, setIsOpen } = useTour();
  const brazeSdk = useNotifications();
  const { setHistory } = useRouteHistory();

  const clearReduxApiState = React.useCallback(() => {
    dispatch(gadaSaasApi.util.resetApiState());
  }, [dispatch]);

  const unregisterPushPermissions = React.useCallback(async () => {
    if (brazeSdk?.isBrazeInit) {
      const { unregisterPush, isPushPermissionGranted } = await import(
        /* webpackExports: ["unregisterPush"] */
        "@braze/web-sdk"
      );
      if (isPushPermissionGranted()) {
        unregisterPush();
      }
    }
  }, [brazeSdk?.isBrazeInit]);

  const handleClickLogout = React.useRef(() => {
    localStorage.setItem(
      "reduxPersist:userMenus",
      JSON.stringify(userMenuInitialState)
    );

    // empty inventory bulk add data to avoid data security leak
    localStorage.removeItem("bulk_add_inventory_form");

    unregisterPushPermissions();
    clearUserToken();
    clearIsLoggedIn();
    analytics.reset();
    if (isOpen) {
      setIsOpen(false);
    }

    logoutFromServer().then(() => {
      router.push(PAGES.REGISTRATION).then(() => {
        // Reset history on logout
        setHistory([PAGES.REGISTRATION]);
        dispatch(globalResetAction());
        clearReduxApiState();
      });
    });
  });

  return handleClickLogout.current;
}
