import React from "react";
import { Modal, ModalProps, Text } from "@gada-saas/web-ui";

type LimitedAccessPopupProps = Omit<ModalProps, "title" | "content">;

const LimitedAccessPopup = (props: LimitedAccessPopupProps) => {
  return (
    <Modal
      title="Menu Terkunci"
      content={
        <Text variant="body1" style={{ width: "80%", paddingBottom: "8px" }}>
          Anda tidak memiliki akses untuk membuka menu ini. Hubungi pemilik toko
          untuk mendapatkan akses.
        </Text>
      }
      {...props}
    />
  );
};

export default LimitedAccessPopup;
