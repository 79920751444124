import * as React from "react";
import { Col } from "../Layout";
import Text from "../Typography/Typography";
import { iEmptyListMessageProps } from "./types";
import { useStyles } from "./styles";
import clsx from "clsx";
import { Button } from "../Button";

const EmptyListMessage: React.FC<iEmptyListMessageProps> = ({
  title,
  titleProps,
  message,
  style = {},
  className,
  buttonText,
  onButtonClick,
}) => {
  const classes = useStyles();

  return (
    <Col
      vCenter
      hCenter
      style={style}
      className={clsx(classes.container, className)}
    >
      <Text variant="h3" align="center" {...titleProps}>
        {title}
      </Text>
      {message && typeof message === "string" ? (
        <Text
          variant="body1"
          align="center"
          ink="neutralPrimary"
          style={{ marginTop: 8 }}
        >
          {message}
        </Text>
      ) : (
        message
      )}
      {buttonText && (
        <Button
          variant="primary"
          className={classes.button}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </Col>
  );
};

export default EmptyListMessage;
