import * as React from "react";
import { iColProps } from "./types";
import { useStyles } from "./styles";
import clsx from "clsx";

const Col = React.forwardRef<HTMLDivElement, iColProps>(
  (
    {
      id,
      children,
      flex,
      vCenter = false,
      hCenter = false,
      fullHeight = false,
      style = {},
      className = "",
      spacing,
      stretchContent = false,
      justifyContent,
      alignItems,
      showBottomSpacing = false,
      ...restProps
    },
    ref
  ) => {
    const classes = useStyles({
      flex,
      vCenter,
      hCenter,
      fullHeight,
      spacing,
      stretchContent,
      justifyContent,
      alignItems,
      showBottomSpacing,
    });

    return (
      <div
        id={id}
        ref={ref}
        className={clsx(classes.container, className)}
        style={style}
        {...restProps}
      >
        {children}
      </div>
    );
  }
);

export default Col;
