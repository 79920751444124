import { useGlobalOfflineState } from "@gada-saas/web-core/offline";
import useOfflineRequestQueueExecutor from "@miscellaneous/offline/hooks/useOfflineRequestQueueExecutor";
import * as React from "react";

export default function OfflineRequestQueueExecutor() {
  const { isOffline } = useGlobalOfflineState();
  const { processQueue, processSavedCartsQueue } =
    useOfflineRequestQueueExecutor();

  React.useEffect(() => {
    if (isOffline) {
      return;
    }

    // Select from queue.. execute + update the db
    processQueue();
    processSavedCartsQueue();
  }, [isOffline, processQueue, processSavedCartsQueue]);

  return null;
}
