import { ButtonProps } from "@gada-saas/web-ui";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";

export interface TourStepContentProps extends PopoverContentProps {
  title?: string;
  description?: string | React.ReactNode;
  primaryButtonText?: string;
  primaryButtonProps?: Omit<ButtonProps, "variant">;
  customPrimaryButtonClick?: VoidFunction;
  secondaryButtonText?: string;
  secondaryButtonProps?: Omit<ButtonProps, "variant">;
  customSecondaryButtonClick?: VoidFunction;
  customDismissButtonClick?: VoidFunction;
  onComponentRendered?: VoidFunction;
  hideStepCount?: boolean;
}
