import * as React from "react";
import { useModalState } from "@common/hooks";
import { Modal, Text } from "@gada-saas/web-ui";
import { iActiveStoreChangeModalProps } from "./types";

const ActiveStoreChangeModal: React.VFC<iActiveStoreChangeModalProps> = ({
  open,
  primaryButtonText = "Ya, hapus",
  secondaryButtonText = "Tidak",
  content,
  ...otherProps
}) => {
  const [_, __, setOpen] = useModalState();

  const onClickCloseButton = React.useCallback(
    (e) => {
      setOpen(false);
      otherProps?.onClickCloseButton?.(e);
    },
    [otherProps, setOpen]
  );

  const onClickPrimaryButton = React.useCallback(
    (e) => {
      setOpen(false);
      otherProps?.onClickPrimaryButton?.(e);
    },
    [otherProps, setOpen]
  );

  const onClickSecondaryButton = React.useCallback(
    (e) => {
      setOpen(false);
      otherProps?.onClickSecondaryButton?.(e);
    },
    [otherProps, setOpen]
  );

  return (
    <Modal
      open={Boolean(open)}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={secondaryButtonText}
      onClickCloseButton={onClickCloseButton}
      onClickPrimaryButton={onClickPrimaryButton}
      onClickSecondaryButton={onClickSecondaryButton}
      content={
        typeof content === "string" ? (
          <Text variant="body1">{content}</Text>
        ) : (
          content
        )
      }
      {...otherProps}
    />
  );
};

export default ActiveStoreChangeModal;
