import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: AppTheme) => ({
  iconButton: {
    padding: 0,
  },
  close: {
    color: theme.palette.interface.neutral.primary,
  },
  endingText: {
    margin: `${pxToRem(24)} ${pxToRem(24)} 0 ${pxToRem(24)}`,
    paddingBottom: pxToRem(16),
  },
  emptyListMessageText: {
    marginTop: pxToRem(8),
  },
}));

export default useStyles;
