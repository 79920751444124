import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../utils";

export const useStyles = makeStyles({
  container: {
    flex: 1,
    padding: pxToRem(16),
  },
  button: {
    marginTop: pxToRem(24),
  },
});
