import { makeStyles, withStyles, Button as MuiButton } from "@material-ui/core";
import { pxToRem, AppTheme } from "@gada-saas/web-ui";
import { ProductProposalStylingParam } from "./types";

const useStyles = makeStyles<
  AppTheme,
  { isExpandPayment?: boolean; isExpandSales?: boolean; elevation?: boolean }
>((theme) => ({
  drawerPaper: {
    width: pxToRem(366),
    boxShadow: (props) =>
      props.elevation ? "-8px 0px 8px rgba(0, 0, 0, 0.04)" : undefined,
  },
  sectionDivider: {
    height: "8px",
    backgroundColor: theme.palette.interface.neutral.light,
  },
  barcodeLabelWrapper: {
    marginTop: pxToRem(8),
  },
  barcodeInput: {
    justifyContent: "space-between",
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    textAlign: "left",
    borderColor: theme.palette.interface.neutral.secondary,
  },
  cameraIcon: {
    color: theme.palette.interface.teal.primary,
  },
  redBorder: {
    borderColor: theme.palette.interface.red.primary,
  },
  icon: {
    color: theme.palette.interface.teal.primary,
  },
}));

export const PlaceholderButton = withStyles(({ palette }: AppTheme) => ({
  root: {
    backgroundColor: palette.interface.white,
    color: palette.interface.neutral.dark,
    borderRadius: pxToRem(8),
    borderColor: ({ error }: { error?: boolean }) =>
      error
        ? palette.interface.red.primary
        : palette.interface.neutral.secondary,
    fontSize: pxToRem(16),
    paddingTop: pxToRem(12),
    paddingBottom: pxToRem(11),
    paddingLeft: pxToRem(16),
    paddingRight: pxToRem(16),
    letterSpacing: "-0.014em",
    fontWeight: "normal",
    justifyContent: "space-between",
    boxShadow: "none",
    textAlign: "left",
  },
}))(MuiButton);

export type ProductProposalFilter = "ALL" | "APPROVED" | "PENDING" | "REJECTED";

export interface StylingParam {
  status: ProductProposalFilter;
}

export const useProposalDetailStyles = makeStyles(({ palette }: AppTheme) => ({
  container: {
    cursor: "pointer",
  },
  label: (props: StylingParam) => ({
    backgroundColor:
      props.status === "APPROVED"
        ? palette.interface.green.primary
        : props.status === "PENDING"
        ? palette.interface.blue.primary
        : props.status === "REJECTED"
        ? palette.interface.red.primary
        : "none",
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    color: palette.interface.white,
    borderRadius: pxToRem(8),
    marginBottom: pxToRem(16),
  }),
  labelNeutral: {
    backgroundColor: palette.interface.neutral.dark,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    color: palette.interface.white,
    borderRadius: pxToRem(8),
  },
  divider: {
    height: pxToRem(8),
    backgroundColor: palette.interface.neutral.light,
  },
  title: {
    marginBottom: pxToRem(8),
  },
  subTitle: {
    marginBottom: pxToRem(16),
  },
  reasonNotes: {
    wordWrap: "break-word",
  },
}));

export const useInventoryProposalBannerStyles = makeStyles<
  AppTheme,
  ProductProposalStylingParam
>(({ palette }) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: pxToRem(16),
    paddingBottom: pxToRem(16),
    paddingLeft: pxToRem(48),
    paddingRight: pxToRem(48),
    backgroundColor: (props) =>
      props.status === "APPROVED"
        ? palette.interface.teal.lighter
        : palette.interface.red.light,
  },
  icon: {
    color: (props) =>
      props.status === "APPROVED"
        ? palette.interface.neutral.primary
        : palette.interface.red.dark,
    cursor: "pointer",
  },
}));

export default useStyles;
