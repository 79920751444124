import SideNav from "@miscellaneous/navigation/components/SideNav/SideNav";
import { Text } from "@gada-saas/web-ui";
import { AppBar, Toolbar } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { useStyles } from "./styles";
import { GridLayoutProps } from "./types";
import OfflineDetector from "@miscellaneous/offline/components/OfflineDetector";
import OfflineRequestQueueExecutor from "@miscellaneous/offline/components/OfflineDetector/OfflineRequestQueueExecutor";
import InventoryProductProposalBanner from "@inventory/common/components/InventoryProductProposalDrawer/InventoryProductProposalBanner";
import OfflineIndicator from "@miscellaneous/offline/components/OfflineIndicator/OfflineIndicator";
import { NotificationNav } from "@miscellaneous/navigation/components/NotificationNav";

const GridLayout = ({
  heading,
  children,
  mainBgColor = "white",
  containerClassName,
  toolbarClassName,
  fullWidthRoot = false,
  onMounted,
  storeChangedProps,
  hideGlobalOfflineIndicator,
  hideSideNavBar = false,
  refs,
}: GridLayoutProps) => {
  const styles = useStyles({ mainBgColor, fullWidthRoot, hideSideNavBar });

  const [notificationDrawer, setNotificationDrawer] =
    React.useState<boolean>(false);

  const toggleNotificationDrawer = React.useCallback(() => {
    setNotificationDrawer((notificationDrawer) => !notificationDrawer);
  }, []);

  React.useEffect(() => {
    onMounted?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      {Boolean(heading) && (
        <AppBar position="fixed" className={styles.appBar} color="transparent">
          <Toolbar className={toolbarClassName}>
            {typeof heading === "string" ? (
              <Text variant="h2" noWrap>
                {heading}
              </Text>
            ) : (
              heading
            )}
          </Toolbar>
          <InventoryProductProposalBanner />
        </AppBar>
      )}
      {!hideSideNavBar && (
        <SideNav
          storeChangedProps={storeChangedProps}
          toggleNotificationDrawer={toggleNotificationDrawer}
        />
      )}
      <NotificationNav
        open={notificationDrawer}
        onClose={toggleNotificationDrawer}
      />
      <main ref={refs?.mainRef} className={styles.content}>
        {Boolean(heading) && <div className={styles.toolbar} />}
        {!Boolean(hideGlobalOfflineIndicator) && <OfflineIndicator />}
        <div className={clsx(styles.mainContent, containerClassName)}>
          {children}
        </div>
      </main>
      <div ref={refs?.rightSectionRef} className={styles.rightSection} />
      <OfflineDetector />
      <OfflineRequestQueueExecutor />
    </div>
  );
};

export default React.memo(GridLayout);
