import { StepType } from "@reactour/tour";
import {
  HasTransactionTutorialStep1,
  HasTransactionTutorialStep2,
  HasTransactionTutorialStep3,
} from "./HasTransactionTutorialSteps";
import {
  AddInventoryChooseSellingUnitTutorialStep,
  AddInventoryChooseStockUnitTutorialStep,
  AddInventoryIntroTutorialStep,
  AddInventorySearchTutorialStep,
  InventoryListEditStockAmountTutorialStep,
  InventoryListSeeCurrentStockTutorialStep,
} from "./InventoryTutorialSteps";
import { POSIntroTutorialStep } from "./POSTutorialSteps/POSIntroTutorialStep";
import { POSOrderCompleteStep } from "./POSTutorialSteps/POSOrderCompleteStep";
import { POSSelectPaymentMethodStep } from "./POSTutorialSteps/POSSelectPaymentMethodStep";

/* NO INVENTORY */
export const addInventoryIntroTutorialSteps: StepType[] = [
  {
    selector: ".aiit-step1",
    content: AddInventoryIntroTutorialStep,
    position: "right",
  },
];

export const inventoryBulkAddPageTutorialSteps: StepType[] = [
  {
    selector: ".ibapt-step1",
    content: AddInventorySearchTutorialStep,
    position: "bottom",
  },
  {
    selector: ".ibapt-step2",
    highlightedSelectors: [".ibapt-step2-h1", ".ibapt-step2-h2", ".ibapt-step2-h3"],
    content: AddInventoryChooseStockUnitTutorialStep,
    position: "bottom",
  },
  {
    selector: ".ibapt-step3",
    highlightedSelectors: [".ibapt-step3-h1", ".ibapt-step3-h2", ".ibapt-step3-h3"],
    content: AddInventoryChooseSellingUnitTutorialStep,
    position: "bottom",
  },
];

/* HAS INVENTORY */
export const inventoryListPageTutorialSteps: StepType[] = [
  {
    selector: ".ilpt-step1",
    content: InventoryListSeeCurrentStockTutorialStep,
    position: "bottom",
  },
  {
    selector: ".ilpt-step2",
    content: InventoryListEditStockAmountTutorialStep,
    position: "bottom",
  },
];

export const posIntroTutorialSteps: StepType[] = [
  {
    selector: ".posit-step1",
    content: POSIntroTutorialStep,
    position: "right",
  },
];

export const posPembayaranTutorialSteps: StepType[] = [
  {
    selector: ".pospt-step1",
    highlightedSelectors: [".pospt-h1", ".pospt-h2"],
    content: POSSelectPaymentMethodStep,
    position: "right",
  },
];

export const posOrderCompleteTutorialSteps: StepType[] = [
  {
    selector: ".posoct-step1",
    content: POSOrderCompleteStep,
    position: "bottom",
  },
];

/* HAS TRANSACTION CHAIN */
export const hasTransactionTutorialSteps: StepType[] = [
  {
    selector: ".htt-step1",
    content: HasTransactionTutorialStep1,
    position: "right",
  },
  {
    selector: ".htt-step2",
    content: HasTransactionTutorialStep2,
    position: "right",
  },
  {
    selector: ".htt-step3",
    content: HasTransactionTutorialStep3,
    position: "right",
  },
];
