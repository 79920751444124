import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";

const POSSelectPaymentMethodStep: React.VFC<PopoverContentProps> = (props) => {
  const analytics = useSegmentAnalytics();

  React.useEffect(() => {
    // stop scrolling
    document.body.style.overflow = "hidden";

    // enable on component un-rendered
    return () => {
      document.body.style.overflow = "scroll";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TourStepContent
      title="Pilih Metode Pembayaran"
      description={
        <Text variant="body2" ink="neutralLight">
          Untuk menyelesaikan transaksi, silahkan pilih satu metode pembayaran
          berikut.
        </Text>
      }
      primaryButtonText="Mengerti"
      customPrimaryButtonClick={() => {
        analytics.track("POS Coachmark Clicked", {
          coachmark_name: "Choose Payment Method",
          action_text: "Mengerti",
          has_inventory: true,
          step_Sequence: 0,
        });
      }}
      customDismissButtonClick={() => {
        analytics.track("POS Coachmark Dismissed", {
          coachmark_name: "Choose Payment Method",
          action_text: "Mengerti",
          has_inventory: true,
          step_Sequence: 0,
        });
      }}
      onComponentRendered={() => {
        analytics.track("POS Coachmark Shown", {
          coachmark_name: "Send Invoice",
          action_text: "Mengerti",
          has_inventory: true,
          step_Sequence: 0,
        });
      }}
      {...props}
    />
  );
};

export default POSSelectPaymentMethodStep;
