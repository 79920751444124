import { PageItems } from "@common/constants/pagesPermissions";
import * as React from "react";
import ItemMenu, { ListItem } from "../ItemMenu";
import {
  Collapse,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { List, useStyles } from "./styles";
import { Text } from "@gada-saas/web-ui";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {
  useSideMenuActions,
  useSideMenuState,
  useUserMenusActions,
} from "@gada-saas/web-core";
import { useUserStorePermission } from "@common/hooks";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";

const ListItemMenu = ({
  id,
  items,
  permissions,
  text,
  icon: Icon,
  withDivider,
}: PageItems) => {
  const analytics = useSegmentAnalytics();
  const styles = useStyles();
  const { expandedMenuIds, activeMenuId } = useSideMenuState();
  const [storePermissions, userHasPermission] = useUserStorePermission();
  const SideMenuActions = useSideMenuActions();
  const UserMenusActions = useUserMenusActions();

  /**
   * ----
   * Formatting
   * ----
   */
  const disableListItem = React.useMemo(
    () => !userHasPermission(permissions),
    [permissions, userHasPermission]
  );

  const expandListItem = React.useMemo(
    () => expandedMenuIds.indexOf(id) > -1,
    [expandedMenuIds, id]
  );

  const listItemSelected = React.useMemo(
    () => activeMenuId === id,
    [activeMenuId, id]
  );

  /**
   * ----
   * Handlers
   * ----
   */
  const handleClickListItem = React.useCallback(() => {
    if (!!storePermissions && !disableListItem) {
      if (expandListItem) {
        analytics.track("Menu List Clicked", {
          name: text,
          section: "Main Menu List",
          is_enabled: true,
          is_collapse: true,
        });
        SideMenuActions.setState({ removeMenuId: id });
      } else {
        analytics.track("Menu List Clicked", {
          name: text,
          section: "Main Menu List",
          is_enabled: true,
          is_collapse: false,
        });
        SideMenuActions.setState({ expandMenuId: id });
      }
    } else {
      UserMenusActions.setShowNoAccessPopup(true);
    }
  }, [
    storePermissions,
    disableListItem,
    expandListItem,
    analytics,
    text,
    SideMenuActions,
    id,
    UserMenusActions,
  ]);

  return (
    <React.Fragment>
      <ListItem
        id={id}
        button
        onClick={handleClickListItem}
        disabled={storePermissions && disableListItem}
        selected={listItemSelected}
      >
        {typeof Icon !== "undefined" && (
          <ListItemIcon className={styles.whiteIcon}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Text variant="h4" ink="white">
              {text}
            </Text>
          }
        />
        {storePermissions &&
          (!disableListItem ? (
            expandListItem ? (
              <ExpandLessIcon className={styles.whiteIcon} />
            ) : (
              <ExpandMoreIcon className={styles.whiteIcon} />
            )
          ) : (
            <LockOutlinedIcon />
          ))}
      </ListItem>
      <Collapse in={expandListItem}>
        <List disablePadding>
          {items.map((item) => (
            <ItemMenu key={item.id} {...item} parentMenuId={id} nested />
          ))}
        </List>
      </Collapse>
      {withDivider && <Divider className={styles.divider} />}
    </React.Fragment>
  );
};

export default ListItemMenu;
