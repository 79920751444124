import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { pxToRem, Text } from "@gada-saas/web-ui";
import { Box } from "@material-ui/core";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { useStyles } from "./styles";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";

const AddInventorySearchTutorialStep: React.VFC<PopoverContentProps> = (
  props
) => {
  const classes = useStyles();
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Menambahkan Barang"
      description={
        <Box>
          <Text variant="body2" ink="neutralLight">
            Cari dan pilih barang yang ingin Anda tambahkan melalui mode
            pencarian atau scan barcode.
          </Text>
          <Box display="flex" mt={pxToRem(10)}>
            <InfoIcon className={classes.infoIconTheme} />
            <Text variant="body2" ink="neutralLight">
              <i>
                <b>Tips:</b> coba masukan beberapa barang favorit di toko Anda
                terlebih dahulu.
              </i>
            </Text>
          </Box>
        </Box>
      }
      primaryButtonText="Mengerti"
      customPrimaryButtonClick={() => {
        analytics.track("Inventory Coachmark Clicked", {
          coachmark_name: "Add Inventory Coachmark",
          action_text: "Mengerti",
          has_inventory: false,
        });
      }}
      customDismissButtonClick={() => {
        analytics.track("Inventory Coachmark Dismissed", {
          coachmark_name: "Add Inventory Coachmark",
          action_text: "Mengerti",
          has_inventory: false,
        });
      }}
      onComponentRendered={() => {
        analytics.track("Inventory Coachmark Shown", {
          coachmark_name: "Add Inventory Coachmark",
          action_text: "Mengerti",
          has_inventory: false,
        });
      }}
      {...props}
    />
  );
};

export default AddInventorySearchTutorialStep;
