import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";

const AddInventoryChooseSellingUnitTutorialStep: React.VFC<PopoverContentProps> =
  (props) => {
    const analytics = useSegmentAnalytics();

    return (
      <TourStepContent
        title="Pilih Unit Penjualan"
        description={
          <Text variant="body2" ink="neutralLight">
            Unit penjualan adalah unit yang dapat dibeli oleh pelanggan Ada.
          </Text>
        }
        primaryButtonText="Mengerti"
        customPrimaryButtonClick={() => {
          analytics.track("Inventory Coachmark Clicked", {
            coachmark_name: "Sell UOM",
            action_text: "Mengerti",
            has_inventory: false,
          });
        }}
        customDismissButtonClick={() => {
          analytics.track("Inventory Coachmark Dismissed", {
            coachmark_name: "Sell UOM",
            action_text: "Mengerti",
            has_inventory: false,
          });
        }}
        onComponentRendered={() => {
          analytics.track("Inventory Coachmark Shown", {
            coachmark_name: "Sell UOM",
            action_text: "Mengerti",
            has_inventory: false,
          });
        }}
        {...props}
      />
    );
  };

export default AddInventoryChooseSellingUnitTutorialStep;
