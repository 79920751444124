import * as React from "react";

export type UsePopOverStateResult<T> = [
  // Button bindings
  { onClick: (event: React.MouseEvent<T>) => void },
  // Popover bindings
  {
    open: boolean;
    anchorEl: T | null;
    onClose: () => void;
  }
];

function usePopoverState<T>(): UsePopOverStateResult<T> {
  const [anchorEl, setAnchorEl] = React.useState<T | null>(null);

  const handleClick = (event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return [
    { onClick: handleClick },
    {
      open,
      anchorEl,
      onClose: handleClose,
    },
  ];
}

export default usePopoverState;
