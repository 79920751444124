import { GetAllTutorialsResponse } from "./models";
import {
  iGetAllTutorialsResponse,
  iGetAllTutorialsResponseData,
} from "./types";

export const transformGetAllTutorialResponse = (
  response: GetAllTutorialsResponse
): iGetAllTutorialsResponse => {
  const newObj: iGetAllTutorialsResponseData["progressList"] = {
    CREATE_INVENTORY: false,
    CREATE_TRANSACTION: false,
    FIRST_TUTORIAL: false,
    ADD_INVENTORY_CHOOSE_SELLING_UNIT: false,
    ADD_INVENTORY_INTRO: false,
    ADD_INVENTORY_SEARCH_PRODUCT: false,
    INVENTORY_LIST_EDIT_STOCK_AMOUNT: false,
    INVENTORY_LIST_RECOUNT_STOCK: false,
    INVENTORY_LIST_SEE_CURRENT_STOCK: false,
    POS_INTRO: false,
    POS_ORDER_COMPLETE: false,
    POS_SELECT_PAYMENT_METHOD: false,
    REPORTING_AFTER_ORDER_COMPLETE: false,
    ADD_INVENTORY_CHOOSE_STOCK_UNIT: false,
    TUTORIAL_BANNER: false,
  };

  response.data.progressList.forEach((tutorial) => {
    newObj[tutorial.tutorialCode] = tutorial.isComplete;
  });

  const newResponse: iGetAllTutorialsResponse = {
    ...response,
    data: {
      progressList: newObj,
    },
  };

  return newResponse;
};
