import { makeStyles, withStyles, List as MuiList } from "@material-ui/core";
import { AppTheme } from "@gada-saas/web-ui";

export const List = withStyles(({ palette }: AppTheme) => ({
  root: {
    backgroundColor: palette.interface.neutral.dark,
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(MuiList);

export const useStyles = makeStyles<AppTheme>((theme) => ({
  divider: {
    backgroundColor: theme.palette.interface.neutral.primary,
    opacity: "0.3",
  },
  whiteIcon: {
    color: theme.palette.interface.white,
  },
  disabledIcon: {
    color: theme.palette.interface.neutral.primary,
  },
}));
