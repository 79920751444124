import { PAGES } from "@common/constants";
import {
  LinkTextActionType,
  useNotifications,
} from "@common/context/NotificationsProvider";
import { PinIcon } from "@common/icons";
import { dayjsInstance } from "@gada-saas/web-core";
import { Button, Col, Row, Text } from "@gada-saas/web-ui";
import { Card } from "@material-ui/core";
import { useRouter } from "next/router";
import * as React from "react";
import { useIntersection } from "react-use";
import useStyles from "./styles";
import { ContentCardProps } from "./types";

const ContentCard = React.forwardRef<HTMLDivElement, ContentCardProps>(
  (
    {
      type,
      categoryIconUrl,
      title,
      imageUrl,
      description,
      linkText,
      isPinned,
      isRead,
      actionType,
      actionUrl,
      detail,
      createdAt,
      updateUnreadContentCards,
      originalCard,
    },
    ref
  ) => {
    const classes = useStyles({ isRead });

    const router = useRouter();
    const brazeSdk = useNotifications();

    const timestamp = React.useMemo(() => {
      const currentDate = dayjsInstance();
      const diff = currentDate.diff(createdAt, "seconds");
      if (diff <= 60) {
        return "baru saja";
      } else if (diff <= 3600) {
        return `${currentDate.diff(createdAt, "minutes")}mnt lalu`;
      } else if (diff <= 86400) {
        return `${currentDate.diff(createdAt, "hours")}jam lalu`;
      } else if (diff <= 172800) {
        return "kemarin";
      }

      return `${dayjsInstance(createdAt).format("DD MMM")}`;
    }, [createdAt]);

    const intersection = useIntersection(ref as React.RefObject<HTMLElement>, {
      root: null,
      threshold: 1,
    });

    const handleRead = React.useCallback(() => {
      if (intersection?.isIntersecting && !isRead) {
        updateUnreadContentCards(originalCard);
      }
    }, [
      intersection?.isIntersecting,
      isRead,
      originalCard,
      updateUnreadContentCards,
    ]);

    React.useEffect(() => {
      handleRead();
    }, [handleRead]);

    const handleCardClick = React.useCallback(async () => {
      if (actionType === LinkTextActionType.URL) {
        window.open(actionUrl, "_blank");
      } else {
        brazeSdk?.setActiveContentCard(detail);
        router.push(PAGES.NOTIFICATION_DETAIL);
      }
    }, [actionType, actionUrl, brazeSdk, detail, router]);

    return (
      <Card ref={ref} className={classes.container} onClick={handleCardClick}>
        {type === "CaptionedImage" && (
          <Col>
            <img
              src={imageUrl}
              className={classes.captionedImage}
              alt="captioned-img"
            />
          </Col>
        )}

        <Row className={classes.contentContainer}>
          {type === "ClassicCard" && (
            <Col className={classes.iconContainer}>
              <img src={categoryIconUrl} alt="img" className={classes.icon} />
            </Col>
          )}

          <Col>
            <Row className={classes.titleContainer}>
              <Text variant="h4" className={classes.title}>
                {title}
              </Text>

              <Text
                variant="captionLight"
                ink="neutralPrimary"
                className={classes.timestamp}
              >
                {timestamp}
              </Text>
            </Row>

            <Text
              variant="body2"
              ink="neutralPrimary"
              className={classes.description}
            >
              {description}
            </Text>

            {linkText && (
              <Button
                variant="text"
                fullWidth={false}
                className={classes.linkTextButton}
                onClick={handleCardClick}
              >
                <Text variant="h5" ink="tealPrimary" noWrap>
                  {linkText}
                </Text>
              </Button>
            )}
          </Col>

          {isPinned && (
            <Col className={classes.pinnedContainer}>
              <PinIcon />
            </Col>
          )}
        </Row>
      </Card>
    );
  }
);

ContentCard.displayName = "ContentCard";

export default ContentCard;
