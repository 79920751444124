import useCoachMark from "@coach-mark/CoachMark/useCoachMark";
import { useAsyncAction } from "@common/hooks";
import {
  useCoachMarkSelector,
  useUpdateTutorialMutation,
} from "@gada-saas/web-core";
import { Button, Col, pxToRem, Row, Text } from "@gada-saas/web-ui";
import { Box, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import * as React from "react";
import useStyles from "./styles";
import { TourStepContentProps } from "./types";

const TourStepContent: React.FC<TourStepContentProps> = ({
  title,
  description,
  primaryButtonText = "",
  primaryButtonProps,
  customPrimaryButtonClick,
  secondaryButtonText = "",
  secondaryButtonProps,
  customSecondaryButtonClick,
  customDismissButtonClick,
  onComponentRendered,
  setIsOpen,
  currentStep,
  setCurrentStep,
  hideStepCount,
}) => {
  const classes = useStyles();

  const [updateTutorial] = useAsyncAction(useUpdateTutorialMutation()[0]);

  const { getCoachMarkChainInfo } = useCoachMark();
  const { chainName, tutorialName, totalTutorialSteps } =
    useCoachMarkSelector();

  const handlePrimaryButtonClick = React.useCallback(() => {
    if (currentStep === totalTutorialSteps - 1) {
      setIsOpen(false);
    } else {
      setCurrentStep((step) => step + 1);
    }
    customPrimaryButtonClick?.();
  }, [
    currentStep,
    customPrimaryButtonClick,
    setCurrentStep,
    setIsOpen,
    totalTutorialSteps,
  ]);

  const handleSecondaryButtonClick = React.useCallback(() => {
    if (currentStep !== 0) {
      setCurrentStep((step) => step - 1);
    }
    customSecondaryButtonClick?.();
  }, [currentStep, customSecondaryButtonClick, setCurrentStep]);

  const toggleDismissModal = React.useCallback(() => {
    setIsOpen(false);
    customDismissButtonClick?.();
  }, [customDismissButtonClick, setIsOpen]);

  // auto complete coach mark when it is rendered
  const onCompMount = React.useCallback(() => {
    if (!chainName || !tutorialName) return;

    onComponentRendered?.();
    const { pastSteps } = getCoachMarkChainInfo(chainName, tutorialName);
    updateTutorial({
      tutorialCodeList: [...pastSteps, tutorialName],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainName, tutorialName]);

  React.useEffect(() => {
    onCompMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompMount]);

  return (
    <Col className={classes.container}>
      <Box className={clsx({ [classes.arrow]: true })} />
      <Row justifyContent="space-between" className={classes.titleContainer}>
        {title && (
          <Text variant="h4" ink="white">
            {title}
          </Text>
        )}
        {!title && (
          <Text variant="h4" ink="white">
            {description}
          </Text>
        )}
        <IconButton
          className={classes.iconButton}
          disableRipple
          onClick={toggleDismissModal}
        >
          <Close className={classes.closeIcon} />
        </IconButton>
      </Row>

      {title && description && typeof description === "string" ? (
        <Text variant="body2" ink="white">
          {description}
        </Text>
      ) : (
        title && description
      )}

      <Box
        display="flex"
        className={classes.buttonsContainer}
        justifyContent="flex-end"
      >
        {!hideStepCount && totalTutorialSteps > 1 && (
          <Box display="flex" alignItems="center" mr="auto" my="auto">
            <Text
              variant="body2"
              ink="neutralSecondary"
              className={classes.flex}
            >
              {currentStep + 1}/{totalTutorialSteps}
            </Text>
          </Box>
        )}

        <Box display="flex" justifyContent="right">
          {secondaryButtonText && (
            <Button
              variant="text"
              onClick={handleSecondaryButtonClick}
              {...secondaryButtonProps}
            >
              {secondaryButtonText}
            </Button>
          )}
        </Box>

        {secondaryButtonText && primaryButtonText && <Box mx={pxToRem(8)} />}

        {primaryButtonText && (
          <Button
            variant="primary"
            className={classes.height}
            onClick={handlePrimaryButtonClick}
            {...primaryButtonProps}
          >
            {primaryButtonText}
          </Button>
        )}
      </Box>
    </Col>
  );
};

export default TourStepContent;
