import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles<AppTheme, { paddingTop: boolean }>(
  ({ palette }) => ({
    container: {
      padding: ({ paddingTop }) =>
        paddingTop
          ? `${pxToRem(32)} ${pxToRem(24)} ${pxToRem(16)} ${pxToRem(24)}`
          : `${pxToRem(16)} ${pxToRem(24)}`,
      overflow: "hidden",
      zIndex: 2,
      width: "100%",
      top: pxToRem(68),
      flexDirection: "column",
      backgroundColor: palette.interface.red.light,
    },
    collapse: {
      minHeight: "auto !important",
    },
  })
);

export default useStyles;
