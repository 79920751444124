import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";
import router from "next/router";
import { PAGES } from "@common/constants";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";

const POSIntroTutorialStep: React.VFC<PopoverContentProps> = (props) => {
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Coba Buat Transaksi Pertama Anda di Kasir"
      description={
        <Text variant="body2" ink="neutralLight">
          Karena Anda sudah memiliki barang di Daftar Barang, yuk coba buat
          transaksi melalui fitur Kasir
        </Text>
      }
      primaryButtonText="Buka Kasir"
      customPrimaryButtonClick={() => {
        analytics.track("Homepage Coachmark Clicked", {
          coachmark_name: "homepage add transactions",
          action_text: "Buka Kasir",
          has_inventory: true,
          has_transactions: false,
          step_Sequence: 0,
        });

        router.push(PAGES.POS);
      }}
      customDismissButtonClick={() => {
        analytics.track("Homepage Coachmark Dismissed", {
          coachmark_name: "homepage add transactions",
          action_text: "Buka Kasir",
          has_inventory: true,
          has_transactions: false,
          step_Sequence: 0,
        });
      }}
      onComponentRendered={() => {
        analytics.track("Homepage Coachmark Shown", {
          coachmark_name: "homepage add transactions",
          action_text: "Buka Kasir",
          has_inventory: true,
          has_transactions: false,
          step_Sequence: 0,
        });
      }}
      {...props}
    />
  );
};

export default POSIntroTutorialStep;
