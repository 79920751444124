import { TourStepContent } from "@coach-mark/components/TourStepContent";
import { Text } from "@gada-saas/web-ui";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { PopoverContentProps } from "@reactour/tour";
import * as React from "react";

const POSOrderCompleteStep: React.VFC<PopoverContentProps> = (props) => {
  const analytics = useSegmentAnalytics();

  return (
    <TourStepContent
      title="Krim dan Print Struk"
      description={
        <Text variant="body2" ink="neutralLight">
          Anda dapat mengirimkan struk atau mencetak struk menggunakan tombol
          ini
        </Text>
      }
      primaryButtonText="Mengerti"
      customPrimaryButtonClick={() => {
        analytics.track("POS Coachmark Clicked", {
          coachmark_name: "Send Invoice",
          action_text: "Mengerti",
          has_inventory: true,
          step_Sequence: 0,
        });
      }}
      customDismissButtonClick={() => {
        analytics.track("POS Coachmark Dismissed", {
          coachmark_name: "Send Invoice",
          action_text: "Mengerti",
          has_inventory: true,
          step_Sequence: 0,
        });
      }}
      onComponentRendered={() => {
        analytics.track("POS Coachmark Shown", {
          coachmark_name: "Choose Payment Method",
          action_text: "Mengerti",
          has_inventory: true,
          step_Sequence: 0,
        });
      }}
      {...props}
    />
  );
};

export default POSOrderCompleteStep;
