import { CircularProgress, IconButton as GIconButton } from "@material-ui/core";
import * as React from "react";
import { useIconButtonStyles } from "./styles";
import { IconButtonProps } from "./types";

const IconButton: React.FC<IconButtonProps> = ({
  children,
  loading = false,
  ...rest
}) => {
  const classes = useIconButtonStyles();

  return (
    <GIconButton className={classes.container} {...rest}>
      {loading ? (
        <div>
          <CircularProgress size={16} />{" "}
        </div>
      ) : (
        children
      )}
    </GIconButton>
  );
};

export default IconButton;
