import { MenuTypes, PagePermissions } from "@common/constants/pagesPermissions";
import {
  useDebouncedCallback,
  useUserStoreRole,
  useQueryParams,
} from "@common/hooks";
import CustomerSupportIcon from "@common/icons/CustomerSupportIcon";
import LimitedAccessPopup from "../LimitedAccessPopup";
import StoreSwitcherPopover from "../StoreSwitcherPopover";
import UserSettingsPopover from "../UserSettingsPopover";
import {
  useGetUserInfoQuery,
  useSideMenuActions,
  useSideMenuState,
  useUserMenusActions,
  useUserMenusState,
} from "@gada-saas/web-core";
import { Button, Col, Row, Text, usePopoverState } from "@gada-saas/web-ui";
import {
  Box,
  Divider,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { useRouter } from "next/router";
import React from "react";
import ItemMenu from "../ItemMenu";
import ListItemMenu from "../ListItemMenu";
import useStyles, { List } from "./styles";
import { iSideNavProps } from "./types";
import { BellIcon, GadaSaasLogo } from "@common/icons";
import { externalUrls } from "@common/constants";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";
import { usePageNameTrackingDataContext } from "@miscellaneous/tracking/contexts/PageNameTrackingContext";
import { useNotifications } from "@common/context/NotificationsProvider";
import {
  addInventoryIntroTutorialSteps,
  hasTransactionTutorialSteps,
  posIntroTutorialSteps,
} from "@coach-mark/components";

const SideNav: React.VFC<iSideNavProps> = ({
  storeChangedProps,
  toggleNotificationDrawer,
}) => {
  const analytics = useSegmentAnalytics();
  const { name: pageName } = usePageNameTrackingDataContext();
  const paperRef = React.useRef<HTMLDivElement>(null);
  const [showShadow, setShowShadow] = React.useState(false);

  const styles = useStyles();

  const [storeDropdownBindings, storePopoverBindings] =
    usePopoverState<HTMLDivElement>();
  const [userDropdownBindings, userPopoverBindings] =
    usePopoverState<HTMLDivElement>();

  const router = useRouter();

  const { activeStore, showNoAccessPopup } = useUserMenusState();
  const SideMenuState = useSideMenuState();
  const { data: userInfoData } = useGetUserInfoQuery();
  const UserMenusActions = useUserMenusActions();
  const SideMenuActions = useSideMenuActions();
  const brazeSdk = useNotifications();

  const userRole = useUserStoreRole();

  const { menuId, subMenuId, expandMenuId } = useQueryParams();

  const totalUnreadCards = React.useMemo(() => {
    if (brazeSdk?.isBrazeInit && brazeSdk.unreadCards > 99) {
      return "99+";
    }

    return brazeSdk?.unreadCards ? brazeSdk?.unreadCards.toString() : 0;
  }, [brazeSdk?.isBrazeInit, brazeSdk?.unreadCards]);

  /**
   * ----
   * Handlers
   * ----
   */
  const saveScrollToSessionStorage = useDebouncedCallback(() => {
    try {
      sessionStorage.setItem(
        "sidenav-scroll",
        paperRef.current!.scrollTop.toString()
      );
    } catch (err) {
      console.log("err", err);
    }
  });

  const handleScrollPaper = React.useCallback(() => {
    if (paperRef.current) {
      const diff =
        Number(paperRef.current.scrollHeight) -
        Number(paperRef.current.offsetHeight);
      if (paperRef.current.scrollTop >= diff) {
        setShowShadow(false);
      } else {
        setShowShadow(true);
      }

      // Preserve scroll state
      saveScrollToSessionStorage();
    }
  }, [saveScrollToSessionStorage]);

  const handleStoreDropdown = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      storeDropdownBindings.onClick(event);
      analytics.track("Top Nav Menu Clicked", {
        name: "Store Selector",
        section: pageName,
        count_store: userInfoData?.data.userStoreList.length,
      });
    },
    [
      analytics,
      pageName,
      storeDropdownBindings,
      userInfoData?.data.userStoreList.length,
    ]
  );

  const handleUserDropdown = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      userDropdownBindings.onClick(event);
      analytics.track("Top Nav Menu Clicked", {
        name: "User Selector",
        section: pageName,
      });
    },
    [analytics, pageName, userDropdownBindings]
  );

  const handleFaqClick = React.useCallback(() => {
    analytics.track("Bottom Nav Menu Clicked", {
      name: "Bantuan & Tutorial",
      section: "Bottom Nav",
    });

    window.open(externalUrls.FAQ, "_blank");
  }, [analytics]);

  /**
   * ----
   * Lifecycles
   * ----
   */
  React.useEffect(() => {
    if (SideMenuState.expandedMenuIds.length) {
      const paperRefCurrent = paperRef.current;
      if (paperRefCurrent) {
        // Wait for animation, and get the final height
        setTimeout(() => {
          const diff =
            Number(paperRefCurrent.scrollHeight) -
            Number(paperRefCurrent.offsetHeight);
          if (diff === paperRefCurrent.scrollTop) {
            setShowShadow(false);
          } else {
            setShowShadow(true);
          }
        }, 500);
      }
    } else {
      setShowShadow(false);
    }
  }, [SideMenuState.expandedMenuIds.length]);

  // Listen to url changes, and set the state according to the url in the address bar
  React.useEffect(() => {
    for (const pageKey in PagePermissions) {
      let shouldBreak;
      const page = PagePermissions[pageKey as MenuTypes];
      if (page.items) {
        const subPages = page.items;
        // Find match of subpage
        for (let i = 0; i < subPages.length; i++) {
          const subPage = subPages[i];
          if (subPage.targetUrl === router.pathname) {
            SideMenuActions.setState({
              activeMenuId: page.id,
              activeSubMenuId: subPage.id,
              expandMenuId: page.id,
            });
            shouldBreak = true; // break outer loop
            break; // break inner loop
          }
        }
      } else {
        if (page.targetUrl === router.pathname) {
          SideMenuActions.setState({
            activeMenuId: page.id,
            activeSubMenuId: "",
          });
          shouldBreak = true;
        }
      }
      if (shouldBreak) break;
    }
  }, [router.pathname, SideMenuActions]);

  // Set scroll offset according to last value;
  React.useEffect(() => {
    try {
      // Read from sessionStorage
      const topOffset = sessionStorage.getItem("sidenav-scroll");

      if (topOffset !== null) {
        paperRef.current!.scrollTop = parseInt(topOffset, 10);
      }
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  React.useEffect(() => {
    SideMenuActions.setState({
      activeMenuId: menuId,
      activeSubMenuId: subMenuId,
      expandMenuId: expandMenuId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuId, expandMenuId]);

  return (
    <Drawer
      className={styles.drawer}
      variant="permanent"
      classes={{
        paper: styles.drawerPaper,
      }}
      anchor="left"
      PaperProps={{
        ref: paperRef,
        onScroll: handleScrollPaper,
      }}
    >
      <Row className={styles.logoContainer}>
        <GadaSaasLogo width="170" height="48" />
        <Button variant="text" onClick={toggleNotificationDrawer}>
          <BellIcon />
          {brazeSdk && brazeSdk.unreadCards > 0 && (
            <Col className={styles.textContainer}>
              <Text variant="labelSemiBold" ink="white">
                {totalUnreadCards}
              </Text>
            </Col>
          )}
        </Button>
      </Row>
      <Box px={6} py={6} className={styles.storeSelector}>
        <Box style={{ cursor: "pointer" }} onClick={handleStoreDropdown}>
          <Text variant="caption" ink="neutralSecondary">
            Toko
          </Text>
          <Box display="flex" marginTop={1}>
            <Text variant="h5" ink="neutralLight">
              {activeStore.storeName}
            </Text>
            {storePopoverBindings.open ? (
              <ExpandLessIcon className={styles.expandIcon} />
            ) : (
              <ExpandMoreIcon className={styles.expandIcon} />
            )}
          </Box>
        </Box>
        <StoreSwitcherPopover
          {...storePopoverBindings}
          storeChangedProps={storeChangedProps}
        />
        <Divider className={styles.switcherDivider} />
        <Box style={{ cursor: "pointer" }} onClick={handleUserDropdown}>
          <Text variant="caption" ink="neutralSecondary">
            {userRole.toLowerCase() === "owner"
              ? "Pemilik"
              : userRole.toLowerCase() === "employee"
              ? "Karyawan"
              : ""}
          </Text>
          <Box display="flex" marginTop={1}>
            <Text variant="h5" ink="neutralLight">
              {userInfoData?.data.userInfo.name}
            </Text>
            {userPopoverBindings.open ? (
              <ExpandLessIcon className={styles.expandIcon} />
            ) : (
              <ExpandMoreIcon className={styles.expandIcon} />
            )}
          </Box>
        </Box>
        <UserSettingsPopover {...userPopoverBindings} />
      </Box>

      <Divider className={styles.divider} />
      <List>
        {/* Render Side Nav Menus */}
        {Object.values(PagePermissions).map((page) => {
          return (
            <Box
              key={page.id}
              // adding class selectors for coach mark
              className={clsx(
                page.id === "2"
                  ? (hasTransactionTutorialSteps[0].selector as string).slice(1)
                  : page.id === "3"
                  ? (posIntroTutorialSteps[0].selector as string).slice(1)
                  : page.id === "6"
                  ? (
                      addInventoryIntroTutorialSteps[0].selector as string
                    ).slice(1)
                  : page.id === "20"
                  ? (hasTransactionTutorialSteps[1].selector as string).slice(1)
                  : page.id === "25" &&
                    (hasTransactionTutorialSteps[2].selector as string).slice(1)
              )}
            >
              {page.items ? <ListItemMenu {...page} /> : <ItemMenu {...page} />}
            </Box>
          );
        })}
        <Box
          className={clsx(
            styles.link,
            styles.stickyMenu,
            showShadow && styles.topShadow,
            "htt-step3"
          )}
          onClick={handleFaqClick}
        >
          <ListItem id="9">
            <ListItemIcon>
              <CustomerSupportIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Text variant="h4" ink="white">
                  Bantuan {"&"} Tutorial
                </Text>
              }
            />
          </ListItem>
        </Box>

        {/* added to stop coach mark info box from glitching when it hovers over the last item inside the menu */}
        <Box py={1} />
      </List>
      <LimitedAccessPopup
        open={showNoAccessPopup}
        withCloseButton
        onClickCloseButton={() => UserMenusActions.setShowNoAccessPopup(false)}
      />
    </Drawer>
  );
};

export default SideNav;
